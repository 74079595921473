// The error 'Uncaught ReferenceError: process is not defined'
// when a User leaves a Meeting is due to internal React / Create React App code,
// it's not our fault. The error is annoying but can/should be ignored.
// https://github.com/facebook/create-react-app/issues/12212

import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import Peer from 'simple-peer';
import { VideoStreamMerger } from 'video-stream-merger';

import { AsyncAPI } from 'app/AsyncAPI/AsyncAPI';
import Icon, { IconSymbol, IconVariant } from 'components/icons/Icon';
import {
  DialogueStateProps,
  mapDialogueStateToProps,
} from 'features/dialogue/dialogueSlice';
import { IUser } from 'features/user/userAPI';
import { UserStateProps, mapUserStateToProps } from 'features/user/userSlice';

import classNames from 'classnames';
import Collapsable from 'components/collapsable/Collapsable';
import { ToolHint } from 'components/messages/ToolHint';
import Author from 'components/user/Author';
import UserAvatar from 'components/user/UserAvatar';
import { getColorForId } from 'helpers/colors';
import { IS_DEV } from 'helpers/consts';
import { ChildBlockProps } from './Block';
import './MeetingGallery.scss';

type VideoProps = {
  peerID?: number;
  peer?: any;
  user: IUser;
  roomId: string;
};

const Video = (props: VideoProps) => {
  const { peerID, peer, user, roomId } = props;
  const ref = useRef<any>();
  const [isAudioOn, setIsAudioOn] = useState(true);
  const [isVideoOn, setIsVideoOn] = useState(true);
  const intl = useIntl();

  useEffect(() => {
    console.log('props.peer: ', props.peer);

    peer.on('stream', (stream: any) => {
      console.log('stream: ', stream);
      try {
        console.log('ref.current: ', ref.current);
        if (ref.current != null) {
          ref.current.srcObject = stream;
          console.log('ref.current.srcObject: ', ref.current.srcObject);
        }
      } catch (err) {
        console.log(err);
      }

      const onMessageCallback = (payload: any) => {
        const incomingEvent = payload.event;
        const incomingPayload = payload.payload;

        switch (incomingEvent) {
          case 'toggled video':
            if (peerID === incomingPayload.id)
              setIsVideoOn(incomingPayload.enabled);
            break;

          case 'toggled audio':
            if (peerID === incomingPayload.id)
              setIsAudioOn(incomingPayload.enabled);
            break;

          default:
            break;
        }
      };

      AsyncAPI.addOnMessageCallbackNonQueries(onMessageCallback, roomId);

      return () => {
        // Cleanup AsyncAPI receiving listener for calling
        const index = AsyncAPI.onMessageCallbacksNonQueries.findIndex(
          ({ callback }) => callback === onMessageCallback
        );

        if (index !== -1) {
          AsyncAPI.onMessageCallbacksNonQueries.splice(index, 1);
        }
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={'meeting_peer'}>
      <video
        playsInline
        autoPlay
        ref={ref}
        style={{
          display: !isVideoOn ? 'none' : undefined,
        }}
      />
      {!isVideoOn ? (
        <div className="meeting_avatar">
          <UserAvatar user={user} medium />
        </div>
      ) : null}
      <div className="meeting_controls">
        {isAudioOn ? null : (
          <div>
            <Icon
              variant={IconVariant.normal}
              symbol={IconSymbol.audio_off}
              size={24}
            />
          </div>
        )}
      </div>
      <div className="meeting_name">{user.username}</div>
    </div>
  );
};

export type MeetingGalleryProps = {};

function UnconnectedMeetingGallery(
  props: MeetingGalleryProps & DialogueStateProps & ChildBlockProps
) {
  const { block, dialogue } = props;
  const [mainSwitch, setMainSwitch] = useState(false);
  const entranceRef = useRef<HTMLDivElement>(null);
  const intl = useIntl();
  const roomId = `meeting_${block.id}`;
  const [webSocket] = useState(AsyncAPI.connection);
  const [preview, setPreview] = useState([]);
  const [open, setOpen] = useState<boolean>(false);

  function getPreview() {
    AsyncAPI.doMessageCall(roomId, 'get preview', {});
  }

  function previewResult(incomingPayload: any) {
    if (incomingPayload != null)
      setPreview(incomingPayload.map((e: any) => e.user));
    else setPreview([]);
  }

  useEffect(() => {
    if (webSocket?.readyState !== 1) return;

    AsyncAPI.joinRoom('calling', roomId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webSocket?.readyState]);

  useEffect(() => {
    if (webSocket?.readyState !== 1) return;
    const onMessageCallback = (payload: any) => {
      if (payload.inner != null) {
        const incomingEvent = payload.inner.event;
        const incomingPayload = payload.inner.payload;

        switch (incomingEvent) {
          case 'preview':
            previewResult(incomingPayload);
            break;

          default:
            break;
        }
      }
    };

    let updatePreviewInterval: NodeJS.Timeout;

    AsyncAPI.addOnMessageCallbackNonQueries(onMessageCallback, roomId);

    getPreview();

    // Start timer/interval
    updatePreviewInterval = setInterval(
      () => {
        getPreview();
      },
      IS_DEV ? 10_000 : 60_000
    ); // 60 000 ms is 1 minute

    return () => {
      AsyncAPI.leaveRoom('calling', roomId);

      const index = AsyncAPI.onMessageCallbacksNonQueries.findIndex(
        ({ callback }) => callback === onMessageCallback
      );

      if (index !== -1) {
        AsyncAPI.onMessageCallbacksNonQueries.splice(index, 1);
      }

      // Clear timer/interval
      clearInterval(updatePreviewInterval);
    };
  }, [dialogue, roomId, webSocket?.readyState]);

  return (
    <Collapsable className="meeting">
      <Collapsable.Controller
        open={open}
        setOpen={setOpen}
        icon={IconSymbol.meeting}
        iconVariant={IconVariant.light}
        noPlus={true}
        iconSize={48}
        hide={open}
      >
        <Icon
          symbol={IconSymbol.left}
          variant={IconVariant.light}
          className="meeting_icon"
          size={18}
        />
        <div className="meeting_label">
          <div className="meeting_label_inner">
            <FormattedMessage id="MEETING.ROOM" />
          </div>
        </div>
      </Collapsable.Controller>
      <Collapsable.Content open={open} dimension="width" className="">
        <div className="meeting_head">
          <Icon
            symbol={IconSymbol.right}
            variant={IconVariant.light}
            className="meeting_icon"
            size={18}
            onClick={() => {
              setOpen((prev) => !prev);
            }}
          />
          <div
            className="meeting_label"
            onDoubleClick={() => {
              setOpen((prev) => !prev);
            }}
          >
            <FormattedMessage id="MEETING.ROOM" />
          </div>
        </div>

        <div className="meeting_content">
          {mainSwitch ? (
            <MeetingGalleryContent {...props} setMainSwitch={setMainSwitch} />
          ) : (
            <>
              <div
                className={'meeting_entrance'}
                onClick={() => setMainSwitch(true)}
                ref={entranceRef}
              >
                <div>
                  <FormattedMessage id="MEETING.JOIN" />
                </div>
                <ToolHint
                  hint={intl.formatMessage({
                    id: preview.length
                      ? 'MEETING.JOIN_HINT'
                      : 'MEETING.START_HINT',
                  })}
                  offset={{ x: 0, y: 80 }}
                  offsetRight={true}
                  toolRef={entranceRef}
                />
              </div>
              <div className="meeting_preview">
                {preview.length > 0 ? (
                  <>
                    <div className="meeting_preview_label">
                      <FormattedMessage id="MEETING.IN_MEETING" />
                    </div>
                    <div className="meeting_preview_users">
                      {preview.map((e: any) => (
                        <div key={e.id}>
                          <UserAvatar user={e} />
                          <Author
                            author={e}
                            color={getColorForId(
                              e.id as number,
                              dialogue.subscribers?.map(
                                (s) => s.id as number
                              ) || []
                            )}
                          />
                        </div>
                      ))}
                    </div>
                  </>
                ) : (
                  <div className="meeting_preview_label">
                    <FormattedMessage id="MEETING.NO_MEETING" />
                    <br />
                    <FormattedMessage id="MEETING.START_MEETING" />
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </Collapsable.Content>
    </Collapsable>
  );
}

const MeetingGallery = connect(mapDialogueStateToProps)(
  UnconnectedMeetingGallery
);
export default MeetingGallery;

type MeetingGalleryContentProps = {
  setMainSwitch: Dispatch<SetStateAction<boolean>>;
};

function UnconnectedMeetingGalleryContent(
  props: MeetingGalleryContentProps &
    ChildBlockProps &
    DialogueStateProps &
    UserStateProps
) {
  const { setMainSwitch, block, user, userCanEdit, dialogue } = props;
  const intl = useIntl();

  const roomId = `meeting_${block.id}`;

  const [webSocket] = useState(AsyncAPI.connection);

  const [peers, setPeers] = useState<any[]>();
  const [microphoneEnabled, setMicrophoneEnabled] = useState<boolean>(true);
  const [cameraEnabled, setCameraEnabled] = useState<boolean>(true);
  const myVideoRef = useRef<any>();
  const videoStream = useRef<any>();
  const peersRef = useRef<any[]>([]);
  const resizing = useRef<any>();
  const constraints = useRef<any>();
  const isResizeModeSupported = useRef(
    navigator.mediaDevices &&
      // @ts-ignore
      navigator.mediaDevices.getSupportedConstraints().resizeMode === true
  );
  const originalStream = useRef<any>();
  const configRef = useRef<any>();

  const joinMeeting = () => {
    // Use Socket.IO (websockets) to emit real-time event to server to notify that current user has joined the room.
    // Send data: roomNumber, current user, contextId and if screen sharing
    AsyncAPI.doMessageCall(roomId, 'join meeting', {
      roomID: roomId,
      // share limited user data
      user: { id: user.id, username: user.username, avatar: user.avatar },
      contextID: dialogue.id,
      screenShare: false,
    });
  };

  // Listen to event from server, get all users in a room
  const allUsers = (users: any[]) => {
    const me = user;

    const peers: any[] = [];

    // For every user in room --> create peer with simple-peer library and push created peer to peers array
    users.forEach((user: any) => {
      const peer = createPeer(
        user.socketid,
        AsyncAPI.id,
        videoStream.current,
        me
      );

      peersRef.current!.push({
        peerID: user.socketid,
        peer,
        user: user.user,
        isSharingScreen: user.screenShare,
      });

      peersRef.current = [...new Set(peersRef.current)];

      peers.push({
        peerID: user.socketid,
        peer,
        user: user.user,
        isSharingScreen: user.screenShare,
      });
    });

    setPeers([...new Set(peers)]);
  };

  // Listen to event from server, when non-current user has joined the room
  const userJoined = (incomingPayload: any) => {
    // Create peer with simple-peer for joined user and push created peer to peers array
    const peer = addPeer(
      incomingPayload.signal,
      incomingPayload.callerID,
      videoStream.current
    );

    peersRef.current!.push({
      peerID: incomingPayload.callerID,
      peer,
      user: incomingPayload.user,
      isSharingScreen: incomingPayload.screenShare,
    });

    peersRef.current = [...new Set(peersRef.current)];

    const peerObj = {
      peer,
      peerID: incomingPayload.callerID,
      user: incomingPayload.user,
      isSharingScreen: incomingPayload.screenShare,
    };

    setPeers((users) => [...(users as any[]), peerObj]);
    setPeers((array) => [...new Set(array)]);
  };

  // Listen to event from server, when handshake request is received from a peer
  const receivingReturnedSignal = (incomingPayload: any) => {
    console.log('receivingReturnedSignal: ', incomingPayload);
    // Find peer that has requested handshake and return handshake (signal)
    const item = peersRef.current!.find((p) => p.peerID === incomingPayload.id);
    console.log('item: ', item);
    item.peer.signal(incomingPayload.signal);
    console.log('itemPeerSignal done');
  };

  // Destroy peer when peer leaves group call
  const userLeft = (incomingPayload: any) => {
    const id = incomingPayload;

    const peerObj = peersRef.current!.find((p) => p.peerID === id);
    if (peerObj) {
      try {
        peerObj.peer.destroy();
      } catch (error) {
        console.log(error);
      }
    }
    const peers = peersRef.current!.filter((p) => p.peerID !== id);
    peersRef.current = [...new Set(peers)];
    setPeers([...new Set(peers)]);
  };

  const cleanupRequest = () => {
    // Send cleanup request to ensure disconnected users are cleaned up in the backend
    AsyncAPI.doMessageCall(roomId, 'cleanup request', {});
  };

  // AsyncAPI - Join room
  useEffect(() => {
    if (webSocket?.readyState !== 1) return;

    AsyncAPI.joinRoom('calling', roomId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webSocket?.readyState]);

  useEffect(() => {
    const onMessageCallback = (payload: any) => {
      // Non-ping-pong messages (special messages)
      if (payload.inner != null) {
        const incomingEvent = payload.inner.event;
        const incomingPayload = payload.inner.payload;

        switch (incomingEvent) {
          case 'all users':
            allUsers(incomingPayload);
            break;

          case 'user joined':
            userJoined(incomingPayload);
            break;

          case 'receiving returned signal':
            receivingReturnedSignal(incomingPayload);
            break;

          case 'user left':
            userLeft(incomingPayload);
            break;

          default:
            break;
        }
      }
      // Ping-pong messages (generic messages)
      else {
        const incomingEvent = payload.event;
        const incomingPayload = payload.payload;

        switch (incomingEvent) {
          default:
            break;
        }
      }
    };

    // Timer/setInterval
    // Every 3 minutes send a cleanup request to the backend
    // To ensure disconnected users are cleaned up properly
    let cleanupInterval: NodeJS.Timeout;

    localStorage.setItem('debug', 'simple-peer');

    constraints.current = isResizeModeSupported.current
      ? {
          audio: true,
          video: {
            height: { ideal: 113 },
            width: { ideal: 190 },
            resizeMode: 'crop-and-scale',
          },
        }
      : {
          audio: true,
          video: true,
        };
    console.log('constraints.current:', constraints.current);
    // Get audio and video input (microphone and camera) from browser
    navigator.mediaDevices
      .getUserMedia(constraints.current)
      .then((stream) => {
        console.log('local tracks: ', stream.getTracks());

        originalStream.current = stream;

        if (isResizeModeSupported.current) {
          // Assign stream to variable
          videoStream.current = stream;
        } else {
          // @ts-ignore
          resizing.current = new VideoStreamMerger({
            height: 113,
            width: 190,
            fps: 30,
          });
          resizing.current.addStream(stream);
          resizing.current.start();
          // Assign stream to variable
          videoStream.current = resizing.current.result;
        }

        // Assign stream to HTML video element
        myVideoRef.current.srcObject = videoStream.current;

        configRef.current = {
          iceServers: [
            {
              urls: 'stun:deliberateturnserver.westeurope.cloudapp.azure.com:3478',
            },
            // {
            //   urls: 'stun:stun.l.google.com:19302',
            // },
            // {
            //   urls: 'stun:stun1.l.google.com:19302',
            // },
            // {
            //   urls: 'stun:stun2.l.google.com:19302',
            // },
            // {
            //   urls: 'stun:stun3.l.google.com:19302',
            // },
            // {
            //   urls: 'stun:stun4.l.google.com:19302',
            // },
            {
              urls: 'turn:deliberateturnserver.westeurope.cloudapp.azure.com:3478',
              username: 'deliberateturnserver',
              credential: 'rtyFdihoij453240foijdsFDSFSDWRWER',
            },
            // {
            //   urls: 'turn:deliberateturnserver.westeurope.cloudapp.azure.com:3478?transport=udp',
            //   username: 'deliberateturnserver',
            //   credential: 'rtyFdihoij453240foijdsFDSFSDWRWER',
            // },
            {
              urls: 'turn:deliberateturnserver.westeurope.cloudapp.azure.com:3478?transport=tcp',
              username: 'deliberateturnserver',
              credential: 'rtyFdihoij453240foijdsFDSFSDWRWER',
            },
          ],
        };
        console.log('configRef.current', configRef.current);

        AsyncAPI.addOnMessageCallbackNonQueries(onMessageCallback, roomId);

        joinMeeting();

        // Start timer/interval
        cleanupInterval = setInterval(() => {
          cleanupRequest();
        }, 180_000); // 180 000 ms is 3 minutes
      })
      .catch((error) => console.log('error: ', error));

    return () => {
      AsyncAPI.doMessageCall(roomId, 'leave meeting', {});
      AsyncAPI.leaveRoom('calling', roomId);

      // Cleanup AsyncAPI receiving listener for calling
      const index = AsyncAPI.onMessageCallbacksNonQueries.findIndex(
        ({ callback }) => callback === onMessageCallback
      );

      if (index !== -1) {
        AsyncAPI.onMessageCallbacksNonQueries.splice(index, 1);
      }

      // Clear timer/interval
      clearInterval(cleanupInterval);

      try {
        if (!isResizeModeSupported.current) {
          videoStream.current = originalStream.current;
          resizing.current.stop();
          resizing.current.destroy();
          resizing.current = null;
        }
        videoStream.current.getTracks().forEach((track: any) => track.stop());
        videoStream.current = null;
      } catch (error) {
        console.log('error: ', error);
      }
    };
  }, []);

  // Function for a new user joining the room, who has to connect to the others currently in the room
  // Signals (handshake) to every other user currently in room
  function createPeer(
    userToSignal: any,
    callerID: any,
    stream: any,
    user: any
  ) {
    console.log('createPeer: ', {
      userToSignal: userToSignal,
      callerID: callerID,
      stream: stream,
      user: user,
    });

    const peer = new Peer({
      initiator: true,
      trickle: false,
      stream,
      config: configRef.current,
    });

    peer.on('signal', (signal) => {
      console.log('createPeer, onSignal: ', signal);

      try {
        AsyncAPI.doMessageCall(roomId, 'sending signal', {
          userToSignal,
          callerID,
          signal,
          user,
        });
      } catch (err) {
        console.log(err);
      }
    });

    return peer;
  }

  // Function for users currently in room when new user joins a room.
  // Those who are currently in the room have to connect to joining user
  // Every user already in group call signals (handshake) to joining user
  function addPeer(incomingSignal: any, callerID: any, stream: any) {
    console.log('addPeer: ', {
      incomingSignal: incomingSignal,
      callerID: callerID,
      stream: stream,
    });

    const peer = new Peer({
      initiator: false,
      trickle: false,
      stream,
      config: configRef.current,
    });

    peer.on('signal', (signal) => {
      console.log('addPeer, onSignal: ', signal);

      try {
        AsyncAPI.doMessageCall(roomId, 'returning signal', {
          signal,
          callerID,
        });
      } catch (err) {
        console.log(err);
      }
    });

    peer.signal(incomingSignal);

    return peer;
  }

  const toggleMicrophone = () => {
    if (videoStream.current != null) {
      videoStream.current.getAudioTracks().forEach((track: any) => {
        track.enabled = !track.enabled;
        setMicrophoneEnabled(track.enabled);
        AsyncAPI.doMessageCall(roomId, 'toggled audio', {
          id: AsyncAPI.id,
          enabled: track.enabled,
        });
      });
    }
  };

  const toggleCamera = () => {
    if (videoStream.current != null) {
      videoStream.current.getVideoTracks().forEach((track: any) => {
        track.enabled = !track.enabled;
        setCameraEnabled(track.enabled);
        AsyncAPI.doMessageCall(roomId, 'toggled video', {
          id: AsyncAPI.id,
          enabled: track.enabled,
        });
      });
    }
  };

  return (
    <>
      <div className={classNames('meeting_menu')}>
        <Icon
          className={'meeting_exit'}
          symbol={IconSymbol.exit}
          onClick={() => {
            setMainSwitch(false);
          }}
          hintProps={{
            hint: intl.formatMessage({
              id: 'MEETING.LEAVE_HINT',
            }),
            offset: { x: 44, y: 28 },
            offsetRight: true,
          }}
          variant={IconVariant.accent}
          size={48}
        />
      </div>
      {/* My video */}
      <div className={'meeting_me'}>
        <video
          muted // avoids our own echo
          playsInline
          autoPlay
          ref={myVideoRef}
          style={{
            display: !cameraEnabled ? 'none' : undefined,
          }}
        />
        {!cameraEnabled ? (
          <div className="meeting_avatar">
            <UserAvatar user={user} medium />
          </div>
        ) : null}
        <div className="meeting_controls">
          <div>
            <Icon
              variant={IconVariant.light}
              symbol={
                microphoneEnabled ? IconSymbol.audio : IconSymbol.audio_off
              }
              hoverVariant={IconVariant.accent}
              size={24}
              onClick={toggleMicrophone}
            />
          </div>
          <div>
            <Icon
              variant={IconVariant.light}
              symbol={cameraEnabled ? IconSymbol.video : IconSymbol.video_off}
              hoverVariant={IconVariant.accent}
              size={24}
              onClick={toggleCamera}
            />
          </div>
        </div>
        <div className="meeting_name">{user.username}</div>
      </div>
      {/* Peer videos */}
      <div className="meeting_peers">
        {peers != null
          ? peers.map((peer) => {
              if (!(peer.user.id === user.id && peer.isSharingScreen))
                return (
                  <Video
                    key={peer.peerID}
                    peerID={peer.peerID}
                    peer={peer.peer}
                    user={peer.user}
                    roomId={roomId}
                  />
                );
              return null;
            })
          : null}
      </div>
    </>
  );
}

const MeetingGalleryContent = connect(mapUserStateToProps)(
  connect(mapDialogueStateToProps)(UnconnectedMeetingGalleryContent)
);
